<template>
  <div class="container">
    <div class="conference-reviewer mt-4 mb-4">
      <h4 class="text-center mb-4">Список одобренных статей</h4>

      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>№</th>
            <th>Название статьи</th>
<!--            <th>Автор</th>-->
            <th>Секция</th>
            <th>Язык</th>
            <th>Файл</th>
            <th>Дата получения</th>
            <th>% плагиата (локальный)</th>
            <th>% плагиата (strike)</th>
            <th>Действия</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(article, articleIndex) in conferenceReviewer_form.conferenceArticle" :key="articleIndex">
            <td>{{ articleIndex + 1 }}</td>
            <td>{{ article.theme }}</td>
<!--            <td>{{ article.last_name }} {{ article.first_name }} {{ article.middle_name }}</td>-->
            <td>{{ article.conference_section_name }}</td>
            <td>{{ article.language }}</td>
            <td>
              <a :href="`https://back.uib.kz/${article.unite_file_url}`">Файл</a>
            </td>
            <td>{{ convertGraduateWorkTimestampToDate(article.created_at) }}</td>
            <td>{{ article.local_plagiat || 'Результата нет' }}</td>
            <td>{{ article.strike_plagiat || 'Результата нет' }}</td>
            <td>
              <div v-if="article.conference_article_status_id != 7 && article.conference_article_status_id != 8 && article.conference_article_status_id != 6">
                <Button icon="pi pi-check" class="p-button-rounded p-button-success m-1"
                        @click="openAddReviewDialog(article.conference_review_id)"/>
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger m-1"
                        @click="openRejectArticleDialog(article.conference_article_id)"/>
              </div>
              <div v-else>

<!--                <Button icon="pi pi-info-circle" class="p-button-info m-1"-->
<!--                        @click="openInfoReviewDialog(article.conference_review_id)"/>-->
              </div>

            </td>

          </tr>
          </tbody>
        </table>
      </div>


      <Dialog v-model:visible="addReviewDisplay" :style="{width: '50vw'}" :closable="false" :modal="true">
        <template #header>
          <h5>Добавить рецензию</h5>
        </template>

        <div class="col-md-12 mt-4" v-for="(reviewCriterion, reviewCriterionIndex) in reviewCriterions"
             :key="reviewCriterionIndex">
          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">{{ reviewCriterion.name }}</label>
            <div class="col-md-6">
              {{ reviewCriterion.text }}
            </div>

            <div class="col-md-3">
              <input :id="'review_score_'+(reviewCriterionIndex+1)" type="number" class="form-control"
                     placeholder="От 1 до 5"
                     @change="changeReview('score_'+(reviewCriterionIndex+1), $event)">
            </div>

          </div>
          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Подтверждающие комментарии</label>
            <div class="col-md-9">
                <textarea class="form-control" placeholder="Не менее 50 символов" rows="5" cols="5"
                          @change="changeReview('comment_'+ (reviewCriterionIndex+1), $event)">
                </textarea>
            </div>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeAddReviewDialog"/>
          <Button label="Добавить" icon="pi pi-check" @click="putReview"/>
        </template>
      </Dialog>


      <!-- reject dialog -->
      <Dialog header="Отклонение" v-model:visible="rejectArticleDisplay" :style="{width: '100%', maxWidth: '600px'}"
              :modal="true" :closable="false">
        <div class="px-1">
          <div class="mb-3">
            <label for="rejectArticleComment" class="form-label">Комментарий</label>
            <textarea v-model="conferenceArticleCommentText" class="form-control" id="rejectArticleComment"
                      rows="3"></textarea>
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeRejectArticleDialog"/>
          <Button label="Отказать"
                  class="p-button-danger"
                  @click="postConferenceArticleComment(8)"/>
          <Button label="Отправить на доработку"
                  class="p-button-danger"
                  @click="postConferenceArticleComment(6)"/>
        </template>
      </Dialog>
      <!-- end reject dialog -->


      <!-- info review dialog -->
      <Dialog v-model:visible="infoReviewDisplay" :style="{width: '50vw'}" :closable="false" :modal="true">
        <template #header>
          <h5>Рецензия</h5>
        </template>

        <div class="col-md-12 mt-4">
          <ol>
            <li>
              Введение: {{ conferenceReviewer_form.conferenceArticle[0].score_1 }}
              <br>
              Комментарий: {{ conferenceReviewer_form.conferenceArticle[0].comment_1 }}
            </li>

            <li>
              Задача исследования: {{ conferenceReviewer_form.conferenceArticle[0].score_2 }}
              <br>
              Комментарий: {{ conferenceReviewer_form.conferenceArticle[0].comment_2 }}
            </li>

            <li>
              Обзор литературы: {{ conferenceReviewer_form.conferenceArticle[0].score_3 }}
              <br>
              Комментарий: {{ conferenceReviewer_form.conferenceArticle[0].comment_3 }}
            </li>

            <li>
              План и методы исследования: {{ conferenceReviewer_form.conferenceArticle[0].score_4 }}
              <br>
              Комментарий: {{ conferenceReviewer_form.conferenceArticle[0].comment_4 }}
            </li>

            <li>
              Полученные данные и обсуждение: {{ conferenceReviewer_form.conferenceArticle[0].score_5 }}
              <br>
              Комментарий: {{ conferenceReviewer_form.conferenceArticle[0].comment_5 }}
            </li>

            <li>
              Научный вклад статьи: {{ conferenceReviewer_form.conferenceArticle[0].score_6 }}
              <br>
              Комментарий: {{ conferenceReviewer_form.conferenceArticle[0].comment_6 }}
            </li>

            <li>
              Выводы: {{ conferenceReviewer_form.conferenceArticle[0].score_7 }}
              <br>
              Комментарий: {{ conferenceReviewer_form.conferenceArticle[0].comment_7 }}
            </li>

            <li>
              Технические требования к статье: {{ conferenceReviewer_form.conferenceArticle[0].score_8 }}
              <br>
              Комментарий: {{ conferenceReviewer_form.conferenceArticle[0].comment_8 }}
            </li>
          </ol>
        </div>


        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeInfoReviewDialog"/>
        </template>
      </Dialog>
      <!-- end info review dialog -->


    </div>


  </div>

</template>


<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate"

export default {
  name: "ConferenceReviewer",

  data() {
    return {
      val: null,
      addReviewDisplay: false,
      infoReviewDisplay: false,
      rejectArticleDisplay: false,
      conferenceArticleCommentText: '',
      conferenceArticleId: null,
      reviewCriterions: [
        {
          id: 1,
          name: 'Введение',
          text: 'Обеспечивает достаточный обзор проблематики исследования'
        },
        {
          id: 2,
          name: 'Задача исследования',
          text: 'Задачи исследования четко сформулированы с соответствующим обоснованием ее важности'
        },
        {
          id: 3,
          name: 'Обзор литературы',
          text: 'Охватывает всесторонне и исчерпывающе соответствующую и современную литературу. Кроме того, обзор литературы включает критический анализ, который дополнительно разъясняет проблему исследования'
        },
        {
          id: 4,
          name: 'План и методы исследования',
          text: 'План исследования четко описан, с адекватным обоснованием выбора методов и четким описанием того, как были проанализированы доказательства. При проведении исследований были соблюдены приемлемые нормы надлежащей исследовательской практики'
        },
        {
          id: 5,
          name: 'Полученные данные и обсуждение',
          text: 'Доказательства или эмпирические данные, представленные в статье (если применимо), являются адекватными. Обсуждение является подробным и показывает глубину понимания, которое обеспечивает прочную основу для вклада в научные знания'
        },
        {
          id: 6,
          name: 'Научный вклад статьи',
          text: 'В целом, статья вносит полезный вклад в продвижение и развитие новых знаний в этой области и, может вызывать дополнительный интерес исследователей и дискуссии.'
        },
        {
          id: 7,
          name: 'Выводы',
          text: 'Демонстрируют четкое понимание ключевых вопросов, резюмируют существенный научный  вклад статьи и дают некоторое направление для будущей работы'
        },
        {
          id: 8,
          name: 'Технические требования к статье',
          text: 'Оцените общее техническое качество статьи'
        },
      ]
    }
  },
  computed: {
    ...mapState('conferenceReviewer', ['conferenceReviewer_form']),
  },
  methods: {
    ...mapActions('conferenceReviewer', ['GET_CONFERENCE_ARTICLE','PUT_CONFERENCE_REVIEW', 'POST_CONFERENCE_ARTICLE_COMMENT']),
    ...mapMutations('conferenceReviewer', ['SET_NEW_REVIEW_DATA', 'SET_CONFERENCE_REVIEW_ID', 'SET_CONFERENCE_ARTICLE_ID', 'CLEAR_NEW_REVIEW']),
    convertGraduateWorkTimestampToDate,
    openAddReviewDialog(conference_review_id) {
      console.log(conference_review_id, 'conference_review_id')
      this.addReviewDisplay = true
      this.SET_CONFERENCE_REVIEW_ID(conference_review_id)
    },
    closeAddReviewDialog() {
      this.addReviewDisplay = false
      this.CLEAR_NEW_REVIEW()
    },
    openRejectArticleDialog(conference_article_id) {
      console.log(conference_article_id, 'conference_article_id')
      this.rejectArticleDisplay = true
      this.conferenceArticleId = conference_article_id
      //this.SET_CONFERENCE_ARTICLE_ID(conference_article_id)
    },
    closeRejectArticleDialog() {
      this.rejectArticleDisplay = false
      this.conferenceArticleCommentText = ''
    },
    openInfoReviewDialog() {
      this.infoReviewDisplay = true
    },
    closeInfoReviewDialog() {
      this.infoReviewDisplay = false
    },
    async putReview() {

      let data = await this.PUT_CONFERENCE_REVIEW()

      if (data) {
        this.addReviewDisplay = false
        await this.GET_CONFERENCE_ARTICLE()
        await this.CLEAR_NEW_REVIEW()
        this.$message({text: 'Сохранено'})
      }
    },
    async postConferenceArticleComment(status) {
      const postData = {
        conference_article_id: this.conferenceArticleId,
        conference_article_status_id: status,
        text: this.conferenceArticleCommentText,
      }
      let data = await this.POST_CONFERENCE_ARTICLE_COMMENT(postData)

      if (data) {
        this.rejectArticleDisplay = false
        this.conferenceArticleCommentText = ''
        await this.GET_CONFERENCE_ARTICLE()
        this.$message({text: 'Сохранено'})
      }
    },

    changeReview(property, e, val = 'value') {
      const value = e.target[val]
      this.SET_NEW_REVIEW_DATA({property, value})
    },

  },

  async mounted() {
    await this.GET_CONFERENCE_ARTICLE()
  }
}
</script>

<style scoped>

</style>
